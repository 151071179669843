<template>
    <div>
        <ComponentLoader v-if="loading" :loading="loading" />
        <div class="" v-else>
            <div class="__no_data" v-if="!industryReporting.length">
                <div class="row">
                    <div class="col-md-11 mt-5 pt-5 text-center">
                        <h2 class="__no_data_text">No Industries available</h2>
                    </div>
                </div>
            </div>
            <div class="industries_wrapper" v-if="industryReporting.length">
                <div class="chart__container" ref="chart">
                    <apexcharts type="donut" width="100%" height="100%" :options="chartOptions"
                        :series="chartOptions.series"></apexcharts>
                </div>
                <div class="separator">

                </div>
                <div class="__industries_list">
                    <h2 class="industries">
                        Industries
                    </h2>
                    <div class="industries-main-list">
                        <ul class="industries_list">
                            <li v-for="industry in industryReporting" :key="industry.id" @click="industryDetails(industry)">
                                <div class="industry_card">
                                    <div class="industry_description">
                                        <div class="_color"
                                            :style="`background-color:${industryColor(industry.industry_name)}`">
                                        </div>
                                        <p class="total">
                                            {{ industry.individual.length }}
                                        </p>
                                        <p class="name">
                                            {{ industry.industry_name }}
                                        </p>
                                    </div>
                                    <div class="industry_percentage">
                                        <div class="percentage">
                                            {{ industryPercentage(industry.individual.length) }}
                                        </div>
                                        <div class="icon">
                                            <img src="@/assets/arrow_icon.svg" alt="img">
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/state/store.js'
import VueApexCharts from 'vue-apexcharts'
import appConfig from '@/app.config'

export default {
    page: {
        title: 'Industries reports',
        meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {
        apexcharts: VueApexCharts,
    },
    data() {
        return {
            loading: false,
            colors: JSON.parse(localStorage.getItem('FinancialReportingColors')) || [
                '#FF5733',
                '#D0A9F5',
                '#2ECC71',
                '#5DADE2',
                '#F5B041'
            ],
        }
    },
    methods: {
        updateChart() {
            this.chartOptions.colors = this.colors.slice(0, this.industryReporting.length);
            this.chartSeries = this.industryReporting.map((item, index) => ({
                name: `Item ${index + 1}`,
                data: [Math.floor(Math.random() * 100)]
            }));
            setTimeout(() => {
                ApexCharts.exec('chart', 'updateOptions', this.chartOptions);
                ApexCharts.exec('chart', 'updateSeries', this.chartSeries);
            }, 500);
        },
        generateColors(count) {
            const colors = [];
            for (let i = 0; i < count; i++) {
                colors.push(this.generateColor());
            }
            return colors;
        },
        generateColor() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        industryDetails(industry) {
            let { id, individual } = industry
            if (!individual.length) {
                this.$notify({
                    title: 'Message',
                    message: 'No Users available',
                });
                return;
            }
            this.$router.push(`/admin/reporting/industries/${id}/users`).catch(err => { })
        },
        industryColor(industryname) {
            let color = this.colors.find((color, index) => {
                return industryname == this.industryLabels[index]
            })
            return color
        },
        industryPercentage(usersLength) {
            let percentage = 0;
            if (this.industryUsersCount == 0) return percentage;
            percentage = (usersLength / this.industryUsersCount) * 100
            return `${percentage.toFixed(2)}%`
        }
    },
    computed: {
        industryReporting() {
            return store.getters['admin/reporting/industryReporting']
        },
        industryUsersCount() {
            return store.getters['admin/reporting/industryUsersCount']
        },
        industryReporting() {
            return store.getters['admin/reporting/industryReporting']
        },
        industryLabels() {
            return store.getters['admin/reporting/industryLabels']
        },
        industriesCount() {
            return store.getters['admin/reporting/industriesCount']
        },
        chartOptions: function () {
            return {
                colors: [],
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: false,
                },
                labels: this.industryLabels,
                plotOptions: {
                    pie: {
                        size: 200,
                        expandOnClick: false,
                        donut: {
                            size: "88%",
                            background: 'transparent',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    offsetY: -10
                                },
                                value: {
                                    show: true,
                                    fontSize: '16px',
                                    color: "#575761",
                                    fontStyle: "normal",
                                    fontWeight: "bold",
                                    fontSize: "29px",
                                    lineHeight: "32px",
                                    formatter: function (val) {
                                        return val
                                    }
                                },
                                total: {
                                    show: true,
                                    label: 'Total Users',
                                    color: '#575761',
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    lineHeight: "13px",
                                    formatter: function (w) {
                                        return w.globals.seriesTotals.reduce((a, b) => {
                                            return a + b
                                        }, 0)
                                    }
                                }
                            }
                        }
                    }
                },
                // this.industriesCount
                series: this.industriesCount,
                responsive: [
                    // {
                    //     breakpoint: 768,
                    //     options: {
                    //         legend: {
                    //             position: "bottom",
                    //             height:200,
                    //             itemMargin: {
                    //                 horizontal:0,
                    //                 vertical:0
                    //         },
                    //         }
                    //     }
                    // },
                    // {
                    //     breakpoint: 1235,
                    //     options: {
                    //         legend: {
                    //             position: "bottom",
                    //             height:220,
                    //             itemMargin: {
                    //                 horizontal:2,
                    //                 vertical:0
                    //         },
                    //         }
                    //     }
                    // }
                ]
            }
        },
    },
    created() {
        this.loading = true
        store.dispatch('admin/reporting/getIndustryReporting').then((data) => {
            if (this.industryReporting.length != this.colors.length) {
                this.colors = this.generateColors(this.industryReporting.length);
                localStorage.setItem('FinancialReportingColors', JSON.stringify(this.colors));
            }
            this.updateChart();
        })
            .finally(() => this.loading = false)
    }
}
</script>

<style scoped lang="scss">
p {
    margin-bottom: 0px !important;
}

.industries {
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-transform: capitalize;
    color: #575761;
    margin-bottom: 1em;

}

.industries_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .chart__container {
        width: 40%;
        padding-top: 4em;
        padding-bottom: 4em;
        height: 404px !important;
    }

    .separator {
        width: 2px;
        min-height: 100%;
        background-color: #F3F3F4;
    }

    .__industries_list {
        width: calc(60% - 2px);
        padding-top: 40px;
        padding-left: 40px;
        margin-right: 40px;
    }
}

.industries_list {
    width: 100%;
    margin: 0px;
    padding: 0px;

    li {
        list-style: none;

        .industry_card {
            display: flex;
            justify-content: space-between;
            background: #F3F3F4;
            border-radius: 6px;
            height: 44px;
            margin-bottom: 0.5em;
            padding-right: 3%;
            padding-left: 3%;
            cursor: pointer;

            .industry_description {
                display: flex;
                height: inherit;
                align-items: center;
                font-family: 'Graphik Regular';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 15px;
                text-transform: capitalize;
                color: #484A4F;

                ._color {
                    width: 13px;
                    height: 13px;
                    background-color: rgb(153, 153, 153);
                    border-radius: 50%;
                    margin-right: 1em;
                }

                .total {
                    margin-right: 1em;
                }
            }

            .industry_percentage {
                display: flex;
                height: inherit;
                align-items: center;

                .icon {
                    margin-left: 2em;
                }
            }
        }
    }
}

.__no_data_text {
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 15px;
    text-transform: capitalize;
    color: #484A4F;
}

.industries-main-list {
    height: 60vh;
    overflow-y: auto;
}
</style>